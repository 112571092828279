import React from 'react';
import eyeImg from "../img/icons8-eye-24.png"

const CustomerViewButton = ({handleCustomerViewClick}) => {

    return (
        <>
            <img src={eyeImg} alt="Mostrar dados do Cliente" title="Mostrar dados do Cliente"
                 style={{cursor: "pointer"}} onClick={handleCustomerViewClick}/>
        </>
    );
};

export default CustomerViewButton;
