import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import DatePicker from "react-datepicker";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Config from "../../core/config";
import {adjustDateToBrowserTimeZone, getOnlyDate} from "../../utils/utils";
import CustomerSelect from "../customer/CustomerSelect";
import {getAllPaymentMethods, getCustomerById} from "../../utils/requestUtils";
import SearchButton from "../../core/SearchButton";
import BackButton from "../common/BackButton";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;

const ReceivableManager = (props) => {
    const {id, onSave, onBack} = props;
    const [, setLoading] = useRecoilState(loading);
    const [showCustomerSelect, setShowCustomerSelect] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [repeatValue, setRepeatValue] = useState(1);
    const [repeat, setRepeat] = useState(false);

    const newEntity = {
        id: undefined,
        customer: undefined,
        description: '',
        value: 0.0,
        date: getOnlyDate(new Date()),
        dueDate: getOnlyDate(new Date()),
        paymentDate: undefined,
        status: 0,
    };
    const [formData, setFormData] = useState(newEntity);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleRepeatValueChange = (e) => {
        const {value} = e.target;
        if (value <= 0 || value > 12) {
            return;
        }
        setRepeatValue(value);
    };

    const handleRepeatChange = (e) => {
        const {checked} = e.target;
        setRepeat(checked);
        setRepeatValue(!checked ? 1 : 2);
    };

    const handleBirthDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['birthDate']: date
        }));
    };

    const handleKidBirthDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['kidBirthDate']: date
        }));
    };

    async function makeQuery() {
        if (id) {
            try {
                const url = apiUrl + '/api/receivables/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        makeQuery();
    }, [id]);

    useEffect(() => {
        getAllPaymentMethods().then((p) => setPaymentMethods(p));
    }, []);

    function clearEntity() {
        setFormData(newEntity);
    }

    const onConfirm = async () => {

        try {
            if (validateInputs()) {
                setLoading(true);
                await new Promise(resolve => setTimeout(resolve, 300));
                if(id) {
                    formData.id = id;
                    await saveEntity(formData);
                } else {
                    const initialDueDate = new Date(formData.dueDate);
                    for (let i = 0; i < repeatValue; i++) {
                        let currentDueDate = new Date(initialDueDate);
                        currentDueDate.setMonth(currentDueDate.getMonth() + i);
                        console.log(currentDueDate);
                        formData.dueDate = currentDueDate;
                        await saveEntity(formData);
                    }
                }
                clearEntity();
                onSave();
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    function validateInputs() {

        if (!formData.customer) {
            alert('Preencha o campo cliente.');
            return false;
        }

        if (!formData.description || formData.description === '') {
            alert('Preencha o campo descrição.');
            return false;
        }

        if (!formData.value || formData.value === 0) {
            alert('Preencha o campo valor.');
            return false;
        }

        if (!formData.date || formData.date === '') {
            alert('Preencha o campo data.');
            return false;
        }

        if (!formData.dueDate || formData.dueDate === '') {
            alert('Preencha o campo data de vencimento.');
            return false;
        }

        return true;
    }

    async function saveEntity(entity) {
        const url = apiUrl + '/api/receivables';
        await makeRequest(url, entity.id ? 'PUT' : 'POST', {
            'Content-Type': 'application/json',
            'Useruuid':Cookies.get('userUUID')
        }, JSON.stringify(entity));
    }

    async function selectCustomer(id) {
        const customer = await getCustomerById(id);
        setFormData((prevData) => ({
            ...prevData,
            customer: customer
        }));
    }

    const handleCustomerItemClick = async (id) => {
        await selectCustomer(id);
        closeCustomerSelect();

    };


    function handleOnClickCustomer() {
        openCustomerSelect();
    }

    const openCustomerSelect = () => {
        setShowCustomerSelect(true);
    };

    const closeCustomerSelect = () => {
        setShowCustomerSelect(false);
    };

    const handleDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['date']: date
        }));
    };

    const handleDueDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['dueDate']: date
        }));
    };
    const handleReceivableDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            paymentDate: getOnlyDate(date)
        }));
    };


    function handlePaymentMethodSelected(id) {

        if(id) {
            const paymentMethod = paymentMethods.find((p) => p.id === Number(id));
            setFormData((prevData) => ({
                ...prevData,
                paymentMethod: paymentMethod
            }));
        }
    }

    return (
        <div>
            {showCustomerSelect && (
                <CustomerSelect onItemClick={handleCustomerItemClick} onClose={closeCustomerSelect}/>)}
            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Recebimento</div>

            {formData.id && (
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Código:</div>
                    <input type="text" style={{backgroundColor: '#CCCCCC'}} value={formData.id} disabled={true}/>
                </div>)}

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Cliente:</div>
                <SearchButton onClick={handleOnClickCustomer}/>
                <input type="text" disabled={true} value={formData.customer ? formData.customer.name : ''} style={{flexGrow: 1}}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Descrição:</div>
                <input type="text" style={{width: '300px'}} value={formData.description} name="description"
                       onChange={handleChange}/>
            </div>
            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Forma de Pagamento:</div>
                <select value={formData.paymentMethod ? formData.paymentMethod.id : undefined}
                        onChange={(e) => handlePaymentMethodSelected(e.target.value)}>
                    <option value={undefined}>Selecione uma Forma de Pagamento</option>
                    {paymentMethods.map((paymentMethod, index) => (
                        <option key={index} value={paymentMethod.id}>
                            {paymentMethod.name}
                        </option>
                    ))}
                </select>
            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Valor:</div>
                <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                       value={formData.value}
                       name="value"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Data de Lançamento:</div>
                <DatePicker
                    className="datepicker"
                    dateFormat="dd/MM/yyyy"
                    selected={adjustDateToBrowserTimeZone(new Date(formData.date))}
                    onChange={handleDateChange}
                    isClearable
                />
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Data de Vencimento:</div>
                <DatePicker
                    className="datepicker"
                    dateFormat="dd/MM/yyyy"
                    selected={adjustDateToBrowserTimeZone(new Date(formData.dueDate))}
                    onChange={handleDueDateChange}
                    isClearable
                />
            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Data de Pagamento:</div>
                <DatePicker
                    className="datepicker"
                    dateFormat="dd/MM/yyyy"
                    selected={formData.paymentDate ? adjustDateToBrowserTimeZone(new Date(formData.paymentDate)) : undefined}
                    onChange={handleReceivableDateChange}
                    isClearable
                />
            </div>

            {!id &&
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="checkbox" checked={repeat}
                               onChange={handleRepeatChange}/>
                        <div>Repetir?</div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="number"
                               style={{width: '100px'}}
                               disabled={!repeat}
                               min={2}
                               max={12}
                               step="1" placeholder="0"
                               value={repeatValue}
                               onChange={handleRepeatValueChange}/>
                        <div> (meses)</div>
                    </div>
                </div>
            }

            <ActionButtonsManagerView>
                <BackButton onClick={onBack}/>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>


        </div>
    );
};

export default ReceivableManager;
