import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import DatePicker from "react-datepicker";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Config from "../../core/config";
import {validateCPF} from "../../core/cpf";
import {searchCEP} from "../../core/Cep";
import {adjustDateToBrowserTimeZone, brazilianStates, getOnlyDate} from "../../utils/utils";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";
import BackButton from "../common/BackButton";
import Cookies from "js-cookie";
import WhatsAppButton from "../../core/WhatsAppButton";
import {validateCNPJ} from "../../core/cnpj";
import CloseButton from "../../CloseButton";
import ModalDiv from "../../ModalDiv";

const apiUrl = Config.apiUrl;

const CustomerViewer = (props) => {
    const {id, onClose} = props;
    const [, setLoading] = useRecoilState(loading);
    const [isCompany, setIsCompany] = useState(false);

    const newEntity = {
        id: undefined,
        name: '',
        address: '',
        addressNumber: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        phone: '',
        email: '',
        instagram: '',
        birthDate: undefined,
        rg: '',
        cpfCnpj: '',
        delivery: false,
        spaceBorneDelivery: false,
        howDidYouFindUs: '',
        referral: '',
        note: '',
        highlightNote: false,
    };

    const newCustomerKid =
        {name: '', birthDate: undefined};

    const [formData, setFormData] = useState(newEntity);

    const [customerKids, setCustomerKids] = useState([newCustomerKid]);

    useEffect(() => {
        setIsCompany(isCompanyCode(formData.cpfCnpj));
    }, [formData.cpfCnpj]);

    function isCompanyCode(code) {
        const isCnpjValid = validateCNPJ(code);
        const numericCpfCnpj = code ? code.replace(/\D/g, '') : '';
        return isCnpjValid || numericCpfCnpj.length > 11;
    }
    // const handleCpfCnpjChange = (e) => {
    //     const {value} = e.target;
    //     setIsCompany(isCompanyCode(value));
    //     handleChange(e);
    // }
    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const handleBirthDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            birthDate: date ? getOnlyDate(date) : undefined
        }));
    };

    const handleKidBirthDateChange = (index, date) => {
        setCustomerKids((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    birthDate: date ? getOnlyDate(date) : undefined
                } : item
            )
        );
    };

    async function makeQuery() {
        if (id) {
            try {
                const url = apiUrl + '/api/customers/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
                setFormData(response);
                setCustomerKids(response.kids);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
            setCustomerKids([newCustomerKid])
        }
    }

    useEffect(() => {
        makeQuery();
    }, [id]);

    useEffect(() => {
        if (customerKids.length === 0 || customerKids[customerKids.length - 1].name && customerKids[customerKids.length - 1].name !== '') {
            setCustomerKids(customerKids);
        }
    }, [customerKids]);

    async function searchCepAndFillAddressInputs() {

        let result = undefined;
        setLoading(true);
        try {
            result = await searchCEP(formData.cep);
        } finally {
            setLoading(false);
        }
        if (result != null) {
            setFormData((prevData) => ({
                ...prevData,
                address: result.street,
                neighborhood: result.neighborhood,
                city: result.city,
                state: result.state,
            }));
        } else {
            alert('CEP não encontrado.');
        }
    }

    function handleKidNameChange(index, name) {
        setCustomerKids((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    name:
                    name
                } : item
            )
        );
    }

    function removeCustomerKid(index) {
        const novaLista = [...customerKids];
        novaLista.splice(index, 1);
        setCustomerKids(novaLista);

    }

    function handleStateChange(idState) {
        setFormData((prevData) => ({
            ...prevData,
            state: idState,
        }));
    }

    return (
        <ModalDiv closeModal={onClose}>
            <div className="header-label">Cliente - {formData.code}</div>

            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Nome:</div>
                    <input disabled={true}type="text" style={{width: '300px'}} value={formData.name} name="name"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>CPF/CNPJ:</div>
                    <input disabled={true}type="text" id="cpfCnpj" value={formData.cpfCnpj} name="cpfCnpj" maxLength="18"
                           onChange={handleChange}/>
                </div>

                {!isCompany &&
                    <>
                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>RG:</div>
                            <input disabled={true}type="text" id="rg" value={formData.rg} name="rg" onChange={handleChange}/>
                        </div>


                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Data de Nascimento:</div>
                            <DatePicker
                                disabled={true}
                                className="datepicker"
                                dateFormat="dd/MM/yyyy"
                                name="birthDate"
                                selected={formData.birthDate ? adjustDateToBrowserTimeZone(new Date(formData.birthDate)) : undefined}
                                onChange={handleBirthDateChange}
                                isClearable
                            />
                        </div>
                    </>
                }


            </div>
            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '80px'}}>CEP:</div>
                    <div>
                        <input disabled={true}style={{width: '80px'}} type="text" id="cep" value={formData.cep} name="cep"
                               onChange={handleChange}/>
                    </div>
                </div>
                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Endereço:</div>
                    <input disabled={true}type="text" style={{width: '297px'}} value={formData.address} name="address"
                           onChange={handleChange} autoComplete={false}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '70px'}}>Número:</div>
                    <input disabled={true}type="number" step="1" placeholder="0" style={{width: '70px'}}
                           value={formData.addressNumber} name="addressNumber"
                           onChange={handleChange} autoComplete={false}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Complemento:</div>
                    <input disabled={true}type="text" style={{width: '310px'}} value={formData.addressComplement}
                           name="addressComplement"
                           onChange={handleChange} autoComplete={false}/>
                </div>

            </div>
            <div style={{display: 'flex'}}>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Bairro:</div>
                    <input disabled={true}type="text" style={{width: '400px'}} id="neighborhood" value={formData.neighborhood}
                           name="neighborhood" onChange={handleChange}/>
                </div>


                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Cidade:</div>
                    <input disabled={true}type="text" style={{width: '290px'}} id="city" value={formData.city}
                           name="city" onChange={handleChange}/>
                </div>


                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Estado:</div>
                    <select disabled={true} value={formData.state} onChange={(e) => handleStateChange(e.target.value)}>
                        <option value="">Selecione...</option>
                        {brazilianStates.map((estado) => (
                            <option key={estado.sigla} value={estado.sigla}>
                                {estado.sigla} - {estado.nome}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div style={{display: 'flex'}}>


                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '150px'}}>Telefone:</div>
                    <div style={{display: 'flex'}}>
                        <input disabled={true}type="text" id="phone" value={formData.phone} name="phone" onChange={handleChange}/>
                        <WhatsAppButton phoneNumber={formData.phone}/>
                    </div>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Email:</div>
                    <input disabled={true}type="text" style={{width: '300px'}} id="email" value={formData.email} name="email"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '190px'}}>Instagram:</div>
                    <input disabled={true}style={{width: '190px'}} type="text" id="instagram" value={formData.instagram}
                           name="instagram"
                           onChange={handleChange}/>
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center', display: 'flex'}}>
                    <div>Entrega?</div>
                    <input disabled={true}type="checkbox" id="delivery" checked={formData.delivery} name="delivery"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '10px', alignItems: 'center', display: 'flex'}}>
                    <div>Entrega com Astronauta?</div>
                    <input disabled={true}type="checkbox" id="spaceBorneDelivery" checked={formData.spaceBorneDelivery}
                           name="spaceBorneDelivery" onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '10px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Como nos encontrou:</div>
                    <input disabled={true}type="text" style={{width: '400px'}} id="howDidYouFindUs"
                           value={formData.howDidYouFindUs}
                           name="howDidYouFindUs" onChange={handleChange}/>
                </div>


                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Indicação:</div>
                    <input disabled={true}type="text" id="referral" value={formData.referral} name="referral"
                           onChange={handleChange}/>
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Observação:</div>
                    <input disabled={true}type='text' style={{width: '600px'}} id="note" value={formData.note}
                           name="note" onChange={handleChange}/>
                </div>
                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center', display: 'flex'}}>
                    <div>Destacar observação?</div>
                    <input disabled={true}type="checkbox" id="highlightNote" checked={formData.highlightNote}
                           name="highlightNote" onChange={handleChange}/>
                </div>
            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Astrolovers:</div>

                <table style={{height: '125px'}}>
                    <thead>
                    <tr>
                        <th>Nome do Astrolover</th>
                        <th>Data de Nascimento</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody style={{overflowY: 'auto'}}>
                    {customerKids.map((item, index) => (
                        <tr>
                            <td>
                                <input disabled={true}type="text"
                                       value={item.name}
                                       onChange={(e) => handleKidNameChange(index, e.target.value)}/>
                            </td>
                            <td>
                                <DatePicker
                                    disabled={true}
                                    className="datepicker"
                                    dateFormat="dd/MM/yyyy"
                                    selected={item.birthDate ? adjustDateToBrowserTimeZone(new Date(item.birthDate)) : undefined}
                                    onChange={(date) => handleKidBirthDateChange(index, date)}
                                    isClearable
                                />
                            </td>
                            <td>
                                {index < customerKids.length - 1 && (
                                    <button disabled={true} className="remove-button"
                                            onClick={() => removeCustomerKid(index)}>Excluir</button>)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <ActionButtonsManagerView>
                <BackButton onClick={onClose}/>
            </ActionButtonsManagerView>


        </ModalDiv>
    );
};

export default CustomerViewer;
